import { config, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faAddressCard as faSolidAddressCard,
  faAnglesRight as faSolidAnglesRight,
  faArrowRight as faSolidArrowRight,
  faArrowsRotate as faSolidArrowsRotate,
  faBagsShopping as faSolidBagsShopping,
  faBan as faSolidBan,
  faBarsStaggered as faSolidBarsStaggered,
  faBoxesStacked as faSolidBoxesStacked,
  faBoxOpenFull as faSolidBoxOpenFull,
  faBrakeWarning as faSolidBrakeWarning,
  faBriefcase as faSolidBriefcase,
  faBug as faSolidBug,
  faBuildingColumns as faSolidBuildingColumns,
  faBurgerSoda as faSolidBurgerSoda,
  faCalendarWeek as faSolidCalendarWeek,
  faCamera as faSolidCamera,
  faCar as faSolidCar,
  faCashRegister as faSolidCashRegister,
  faChartLine as faSolidChartLine,
  faChartPieSimple as faSolidChartPieSimple,
  faCheck as faSolidCheck,
  faCheckCircle as faSolidCheckCircle,
  faChevronDown as faSolidChevronDown,
  faChevronLeft as faSolidChevronLeft,
  faCircle as faSolidCircle,
  faCircleInfo as faSolidCircleInfo,
  faCircleQuestion as faSolidCircleQuestion,
  faCircleXmark as faSolidCircleXmark,
  faClock as faSolidClock,
  faCopy as faSolidCopy,
  faCrown as faSolidCrown,
  faDeleteRight as faSolidDeleteRight,
  faDisplay as faSolidDisplay,
  faDollarSign as faSolidDollarSign,
  faDollyEmpty as faSolidDollyEmpty,
  faEllipsis as faSolidEllipsis,
  faEllipsisVertical as faSolidEllipsisVertical,
  faEnvelope as faSolidEnvelope,
  faEye as faSolidEye,
  faEyeSlash as faSolidEyeSlash,
  faFileExport as faSolidFileExport,
  faFilter as faSolidFilter,
  faFloppyDisk as faSolidFloppyDisk,
  faGrid2 as faSolidGird2,
  faHandHoldingBox as faSolidHandHoldingBox,
  faHouseUser as faSolidHouseUser,
  faImage as faSolidImage,
  faImageSlash as faSolidImageSlash,
  faInboxIn as faSolidInboxIn,
  faInboxOut as faSolidInboxOut,
  faLayerPlus as faSolidLayerPlus,
  faList as faSolidList,
  faLocationDot as faSolidLocationDot,
  faLock as faSolidLock,
  faMagnifyingGlass as faSolidMagnifyingGlass,
  faMagnifyingGlassChart as faSolidMagnifyingGlassChart,
  faMerge as faSolidMerge,
  faMinus as faSolidMinus,
  faMobileSignalOut as faSolidMobileSignalOut,
  faMoneyBill as faSolidMoneyBill,
  faMoon as faSolidMoon,
  faMusic as faSolidMusic,
  faPanFrying as faSolidPanFrying,
  faPenToSquare as faSolidPenToSquare,
  faPercent as faSolidPercent,
  faPersonCarryBox as faSolidPersonCarryBox,
  faPlus as faSolidPlus,
  faPowerOff as faSolidPowerOff,
  faPrint as faSolidPrint,
  faPrintSlash as faSolidPrintSlash,
  faQrcode as faSolidQrCode,
  faRouteInterstate as faSolidRouteInterstate,
  faSitemap as faSolidSitemap,
  faSliders as faSolidSliders,
  faSort as faSolidSort,
  faSortDown as faSolidSortDown,
  faSortUp as faSolidSortUp,
  faSpinnerThird as faSolidSpinnerThird,
  faSplit as faSolidSplit,
  faSquareList as faSolidSquareList,
  faSquareMinus as faSolidSquareMinus,
  faStore as faSolidStore,
  faStoreSlash as faSolidStoreSlash,
  faSunBright as faSolidSunBright,
  faTablePicnic as faSolidTablePicnic,
  faTimer as faSolidTimer,
  faTrashCan as faSolidTrashCan,
  faUser as faSolidUser,
  faUserLock as faSolidUserLock,
  faUserShield as faSolidUserShield,
  faWineBottle as faSolidWineBottle,
  faXmark as faSolidXmark,
  faParkingCircle as faSolidParkingCircle,
  faFlashlight as faSolidFlashlight,
  faArrowsCross as faSolidArrowsCross,
  faWindowMaximize as faSolidWindowMaximize,
  faListDropdown as faSolidListsDropdown,
  faCircleExclamation as faSolidCircleExclamation,
  faHome as faSolidHome,
  faClipboardList as faSolidClipboardList,
  faExpand as faSolidExpand,
  faCompress as faSolidCompress,
  faWifi as faSolidWifi,
  faWifiSlash as faSolidWifiSlash,
  faUserRobot as faSolidUserRobot,
  faCloud as faSolidCloud,
  faCloudSlash as faSolidCloudSlash,
  faChevronUp as faSolidChevronUp,
  faFileSlash as faFileSlash,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faCreditCard,
  faMoneyCheckPen,
} from "@fortawesome/pro-regular-svg-icons";
// import {} from "@fortawesome/pro-light-svg-icons";
// import {} from '@fortawesome/pro-thin-svg-icons'
import {
  faLockKeyhole as faDuaToneLockKeyhole,
  faLockKeyholeOpen as faDuaToneLockKeyholeOpen,
  faFileLock as faDuaToneFileLock,
} from "@fortawesome/pro-duotone-svg-icons";
// import {} from "@fortawesome/free-brands-svg-icons";

library.add(
  faFileSlash,
  faSolidEyeSlash,
  faDuaToneFileLock,
  faSolidChevronUp,
  faSolidQrCode,
  faMoneyCheckPen,
  faSolidMobileSignalOut,
  faSolidMusic,
  faDuaToneLockKeyholeOpen,
  faDuaToneLockKeyhole,
  faSolidGird2,
  faSolidSunBright,
  faSolidMoon,
  faSolidEnvelope,
  faSolidLock,
  faSolidUserLock,
  faSolidXmark,
  faSolidEllipsisVertical,
  faSolidBarsStaggered,
  faSolidChartPieSimple,
  faSolidChevronDown,
  faSolidWineBottle,
  faSolidCar,
  faSolidTrashCan,
  faSolidSort,
  faSolidCheck,
  faSolidCheckCircle,
  faSolidCircleXmark,
  faSolidPenToSquare,
  faSolidPlus,
  faSolidDeleteRight,
  faSolidPrint,
  faSolidEye,
  faSolidMagnifyingGlass,
  faSolidBoxOpenFull,
  faSolidSortUp,
  faSolidSortDown,
  faSolidBrakeWarning,
  faSolidBug,
  faSolidBan,
  faSolidMinus,
  faSolidUser,
  faSolidRouteInterstate,
  faSolidArrowRight,
  faSolidStoreSlash,
  faSolidBagsShopping,
  faSolidChartLine,
  faSolidHouseUser,
  faSolidTimer,
  faSolidBriefcase,
  faSolidLocationDot,
  faSolidClock,
  faSolidPowerOff,
  faSolidDollyEmpty,
  faSolidFilter,
  faSolidSitemap,
  faSolidChevronLeft,
  faSolidTablePicnic,
  faSolidBurgerSoda,
  faSolidArrowsRotate,
  faSolidCircleInfo,
  faSolidSquareMinus,
  faSolidSpinnerThird,
  faSolidLayerPlus,
  faSolidPanFrying,
  faSolidAnglesRight,
  faSolidInboxIn,
  faSolidInboxOut,
  faSolidFloppyDisk,
  faSolidEllipsis,
  faSolidBuildingColumns,
  faSolidMoneyBill,
  faCreditCard,
  faSolidHandHoldingBox,
  faSolidCamera,
  faSolidImageSlash,
  faSolidDisplay,
  faSolidCircleQuestion,
  faSolidCopy,
  faSolidFileExport,
  faSolidSliders,
  faSolidPercent,
  faSolidImage,
  faSolidMagnifyingGlassChart,
  faSolidCircle,
  faSolidDollarSign,
  faSolidBoxesStacked,
  faSolidList,
  faSolidPersonCarryBox,
  faSolidCrown,
  faSolidUserShield,
  faSolidPrintSlash,
  faSolidCashRegister,
  faSolidMerge,
  faSolidSplit,
  faSolidPersonCarryBox,
  faSolidCalendarWeek,
  faSolidSquareList,
  faSolidAddressCard,
  faSolidStore,
  faSolidParkingCircle,
  faSolidFlashlight,
  faSolidArrowsCross,
  faSolidWindowMaximize,
  faSolidListsDropdown,
  faSolidWineBottle,
  faSolidCar,
  faSolidCircleExclamation,
  faSolidHome,
  faSolidClipboardList,
  faSolidExpand,
  faSolidCompress,
  faSolidWifi,
  faSolidWifiSlash,
  faSolidUserRobot,
  faSolidCloud,
  faSolidCloudSlash
);

config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon);
});
